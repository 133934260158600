<template>
  <div style="background-color: #ebedf0">
    <MyHeader :title="$t('lang.menu_patroldetails')" :ShowBack="true" />
    <div class="repair-panel">
      <div>{{ $t("lang.time") }}：{{ mData.reportTime }}</div>
      <div>{{ $t("lang.router") }}：{{ mData.routeName }}</div>
      <div>
        {{ $t("lang.event") }}：{{ mData.placeName }}-{{ mData.eventName }}
      </div>
      <div>{{ $t("lang.inspector") }}：{{ mData.inspectorName }}</div>
      <div>{{ $t("lang.eventvalue") }}：{{ mData.eventValue }}</div>
    </div>

    <div class="repair-panel">
      <div class="repair-radio" v-for="m in mImage" :key="m">
        <van-image width="62" height="62" :src="m" @click="onShowImg(0)" />
      </div>
      <div class="repair-radio" v-show="mData.reportRecordUrl != ''">
        <van-icon
          name="music"
          size="40"
          style="margin-top: 10px"
          @click="onRecord()"
        />
      </div>
      <div class="repair-radio" v-show="mData.reportVideoUrl != ''">
        <van-icon
          name="play-circle"
          size="40"
          style="margin-top: 10px"
          @click="onVideo()"
        />
      </div>
      <div style="clear: both"></div>
    </div>

    <div class="repair-panel">
      <div>{{ $t("lang.level") }}：{{ mData.repairLevel }}</div>
    </div>

    <div class="repair-panel">
      <van-steps direction="vertical" :active="3 - mRepairProgress">
        <van-step v-show="mRepairProgress >= 3 && mData.finishTime != ''">
          <div>
            【{{ $t("lang.completed") }}】&nbsp;&nbsp;{{
              mData.finishTime
            }}&nbsp;&nbsp;{{ mData.repairUser }}
          </div>
          <p>{{ mData.repairComment }}</p>
        </van-step>
        <van-step v-show="mRepairProgress >= 2 && mData.inProcessTime != ''">
          <div>
            【{{ $t("lang.processing") }}】&nbsp;&nbsp;{{
              mData.inProcessTime
            }}&nbsp;&nbsp;{{ mData.repairUser }}
          </div>
          <p>{{ mData.inProcessComment }}</p>
        </van-step>
        <van-step v-show="mRepairProgress >= 1 && mData.reviewTime != ''">
          <div>
            【{{ $t("lang.audited") }}】&nbsp;&nbsp;{{
              mData.reviewTime
            }}&nbsp;&nbsp;{{ mData.reviewUser }}
          </div>
          <p>{{ mData.reviewComment }}</p>
        </van-step>
        <van-step v-show="mRepairProgress >= 0 && mData.reportTime != ''">
          <div>
            【{{ $t("lang.applied") }}】&nbsp;&nbsp;{{
              mData.reportTime
            }}&nbsp;&nbsp;{{ mData.inspectorName }}
          </div>
          <p>{{ mData.reportComment }}</p>
        </van-step>
      </van-steps>
    </div>

    <van-dialog
      v-model="showVideo"
      show-cancel-button
      :show-confirm-button="false"
      :cancel-button-text="$t('lang.close')"
      @close="onClose()"
    >
      <video-player
        class="video-player-box vjs-big-play-centered"
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="true"
        customEventName="customstatechangedeventname"
      >
      </video-player>
    </van-dialog>
  </div>
</template>
<style scoped>
.repair-panel {
  padding: 10px;
  font-size: 14px;
  line-height: 24px;
  color: #646566;
  margin-top: 5px;
  background-color: #fff;
}
.van-image {
  margin-right: 5px;
}
.repair-radio {
  height: 62px;
  width: 62px;
  margin-right: 10px;
  border: 1px solid #ddd;
  background-color: #eee;
  float: left;
  text-align: center;
}
</style>
<script>
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import { ImagePreview } from "vant";
import MyHeader from "@/components/Header.vue";
import { GetRepairDetail, GetServerUrl } from "@/api/index.js";

export default {
  components: { MyHeader, videoPlayer },
  data() {
    return {
      mData: {},
      mImage: [],
      mImage1: [],
      mRepairProgress: 0,

      detailid: 0,
      ServerUrl: "",

      showVideo: false,
      playerOptions: {
        muted: false,
        language: "zh-CN",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        fluid: true,
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
    };
  },

  mounted() {
    this.ServerUrl = GetServerUrl();
    this.detailid = this.$route.query.id;
    this.Query();
  },

  methods: {
    onShowImg(i) {
      ImagePreview({
        images: this.mImage1,
        startPosition: i,
      });
    },

    onRecord() {
      this.playerOptions.sources[0].src =
        this.ServerUrl + this.mData.reportRecordUrl;
      this.showVideo = true;
    },

    onVideo() {
      this.playerOptions.sources[0].src =
        this.ServerUrl + this.mData.reportVideoUrl;
      this.showVideo = true;
    },

    onClose() {
      this.$refs.videoPlayer.player.pause();
    },

    Query() {
      GetRepairDetail(
        {
          token: this.$store.getters.getToken,
          id: this.detailid,
        },
        (ret) => {
          if (ret && ret.code == 0) {
            this.mData = ret.data;
            this.mRepairProgress = this.mData.state;
            let imgurl = this.mData.reportImageUrl.replace(
              RegExp("/app/images", "g"),
              this.ServerUrl + "app/images"
            );
            this.mImage = imgurl.split("|");
            this.mImage1 = imgurl
              .replace(RegExp("preview/", "g"), "")
              .split("|");
          } else {
            this.$Toast(!ret ? this.$t("lang.queryfail") : ret.message);
          }
        }
      );
    },
  },
};
</script>
